/** Transition slide. */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fade-out {
  animation-name: fade-out;
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
}
