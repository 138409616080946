#main-menu {
  @extend %cover;

  background-color: white;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  overflow: hidden;
  z-index: 55;

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 5;

    svg rect {
      fill: #0071bb;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;

    .navigation {
      margin: 0 0 32px;

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        li {
          &:not(:last-child) {
            margin: 0 0 20px;
          }

          a {
            color: #0071bb;
            font-size: 14px;
            font-weight: 700;
            display: inline-block;
            transition-property: color;
            transition-duration: time(default);
            transition-duration: ease(inout);

            &:hover {
              color: #e95116;
            }
          }
        }
      }
    }

    .login {
      margin: 0 0 32px;
    }

    .languages {
      > div {
        padding: 0;
        border: none;

        ul {
          display: flex;
          align-items: center;

          li {
            position: relative;
            margin: 0;
            padding: 0;
            list-style: none;

            &:not(:last-child) {
              margin-right: 20px;

              &::after {
                content: '|';
                position: absolute;
                color: #21bbef;
                font-size: 20px;
                font-weight: normal;
                position: absolute;
                right: -15px;
                bottom: 1px;
              }
            }

            a {
              color: #0071bb;
              font-size: 14px;
              font-weight: normal;
              padding: 0;
            }

            &.wpml-ls-current-language a {
              font-weight: bold;
              border-bottom: 1px solid #0071bb;
            }
          }
        }
      }
    }
  }
}
