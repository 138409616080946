%cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

a%cover {
  text-indent: -999px;
  overflow: hidden;
}

button%cover {
  text-indent: -999px;
  overflow: hidden;
}
