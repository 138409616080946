.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 32px 0;
  height: 50px;

  &::before {
    @extend %cover;

    content: '';
    clip-path: polygon(8px 0, 100% 0, calc(100% - 8px) 100%, 0 100%);
    transition-property: clip-path, background-color;
    transition-duration: time(fast);
    transition-timing-function: ease(inout);
    z-index: 1;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 2;
  }

  @media (min-width: 1024px) {
    &:hover {
      &:before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  &-orange {
    &::before {
      background-color: #e95116;
    }

    span {
      color: white;
    }

    @media (min-width: 1024px) {
      &:hover {
        &::before {
          background-color: #21bbef;
        }
      }
    }
  }

  &-blue {
    &::before {
      background-color: #21bbef;
    }

    span {
      color: white;
    }

    @media (min-width: 1024px) {
      &:hover {
        &::before {
          background-color: #e95116;
        }
      }
    }
  }
}
