#main-footer {
  background-color: white;
  padding: 60px 0 35px;

  @media (max-width: 1023px) {
    padding: 75px 0 35px;
  }

  .flex-wrapper {
    padding-right: 55px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    .left {
      @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-navigation {
        margin-top: 70px;

        @media (max-width: 1023px) {
          margin-top: 40px;
        }

        ul {
          display: flex;
          align-items: flex-start;

          li {
            &:not(:last-child) {
              margin-right: 40px;

              @media (max-width: 1023px) {
                margin-right: 20px;
              }
            }

            a {
              color: #0071bb;
              font-size: 14px;
              font-weight: 500;
              transition-property: color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              &:hover {
                color: #e95116;
              }
            }
          }
        }
      }

      &-copyright {
        margin-top: 45px;

        p {
          color: #333333;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .right {
      @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
      }

      &-social {
        ul {
          display: flex;
          align-items: ce;

          li {
            &:not(:last-child) {
              margin-right: 32px;

              @media (max-width: 1023px) {
                margin-right: 20px;
              }
            }

            a {
              display: inline-flex;
              align-items: center;

              svg {
                width: 22px;

                path {
                  transition-property: fill;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              &:hover {
                span {
                  color: #e95116;
                }

                svg path {
                  fill: #e95116;
                }
              }
            }
          }
        }
      }

      &-signature {
        margin-top: 95px;

        @media (max-width: 1023px) {
          margin-top: 40px;
        }
      }
    }
  }
}
