.button::before, #main-menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; }

a.button::before, a#main-menu {
  text-indent: -999px;
  overflow: hidden; }

button.button::before, button#main-menu {
  text-indent: -999px;
  overflow: hidden; }

/**
* Base.
*/
body {
  background-color: #eeeeee;
  position: relative;
  font-family: "Campuni";
  font-size: 16px;
  width: 100%;
  overflow-x: hidden; }
  body::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms; }
  body.shadow {
    width: 100%;
    overflow: hidden; }
    body.shadow::before {
      opacity: 0.6;
      pointer-events: initial; }

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto; }
  @media (max-width: 1300px) {
    .container {
      padding: 0 25px; } }

* {
  font-family: "Campuni";
  font-display: swap;
  line-height: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style-type: none; }

s {
  text-decoration: none;
  display: inline-block;
  transition-property: transform;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }

form {
  font-family: "Campuni"; }
  form input {
    line-height: initial; }
  form input,
  form textarea,
  form select {
    outline: none !important; }
    form input:active, form input:focus,
    form textarea:active,
    form textarea:focus,
    form select:active,
    form select:focus {
      outline: none !important; }

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  outline: none !important; }

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }

button,
button:active,
button:focus {
  outline: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0; }

ul,
ol {
  margin: 0;
  list-style-type: none; }

label {
  font-weight: normal; }

@font-face {
  font-family: 'Campuni';
  src: url("../../assets/fonts/Campuni-Black.woff2") format("woff2"), url("../../assets/fonts/Campuni-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Campuni';
  src: url("../../assets/fonts/Campuni-Bold.woff2") format("woff2"), url("../../assets/fonts/Campuni-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Campuni';
  src: url("../../assets/fonts/Campuni-Regular.woff2") format("woff2"), url("../../assets/fonts/Campuni-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 32px 0;
  height: 50px; }
  .button::before {
    content: '';
    clip-path: polygon(8px 0, 100% 0, calc(100% - 8px) 100%, 0 100%);
    transition-property: clip-path, background-color;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    z-index: 1; }
  .button span {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 2; }
  @media (min-width: 1024px) {
    .button:hover:before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }
  .button-orange::before {
    background-color: #e95116; }
  .button-orange span {
    color: white; }
  @media (min-width: 1024px) {
    .button-orange:hover::before {
      background-color: #21bbef; } }
  .button-blue::before {
    background-color: #21bbef; }
  .button-blue span {
    color: white; }
  @media (min-width: 1024px) {
    .button-blue:hover::before {
      background-color: #e95116; } }

.appear-fade,
.appear-fade-horizontal,
.appear-fade-vertical {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  .appear-fade.appear,
  .appear-fade-horizontal.appear,
  .appear-fade-vertical.appear {
    opacity: 1;
    transform: translate(0, 0); }

.appear-fade-vertical {
  transform: translate(0, 15px); }

.appear-fade-horizontal {
  transform: translate(15px, 0); }

[rel='lazy_load'] {
  overflow: hidden; }

@keyframes lazy_load {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 50%; }
  50% {
    transform: scaleX(1);
    transform-origin: 0% 50%; }
  50.1% {
    transform: scaleX(1);
    transform-origin: 100% 50%; }
  100% {
    transform: scaleX(0);
    transform-origin: 100% 50%; } }
  [rel='lazy_load']:not(.no-animate) img {
    z-index: 1;
    transform-origin: 25% 25%;
    opacity: 0;
    transform: scale(1.08);
    transition-property: opacity, transform;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].no-animate img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;
    transition-timing-function: ease; }
  [rel='lazy_load'].loaded:not(.no-animate) img {
    opacity: 1;
    transform: scale(1); }
  [rel='lazy_load'].loaded.no-animate img {
    opacity: 1; }

.screen-progress {
  background-color: #0071bb;
  color: white;
  text-align: center;
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top 0.7s cubic-bezier(0.5, 0, 0, 1), opacity 0.7s cubic-bezier(0, 0, 0.3, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  .screen-progress.open {
    margin-top: 0;
    opacity: 1; }
  .screen-progress .screen-message {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px; }
  .screen-progress .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative; }

@keyframes progress_spinner {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: 0.3; }
  100% {
    transform: scale(1);
    opacity: 0; } }
    .screen-progress .spinner span {
      background-color: white;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite; }
      .screen-progress .spinner span:nth-child(2) {
        animation-delay: 0.5s; }

/** Transition slide. */
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fade-out;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

/**
* Globals.
*/
#main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition-property: background-color;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  z-index: 50; }
  #main-header .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    transition-property: padding;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    @media (max-width: 1023px) {
      #main-header .flex-wrapper {
        padding: 25px 0; } }
    #main-header .flex-wrapper .logo {
      position: relative; }
      #main-header .flex-wrapper .logo::before {
        content: '';
        background-color: white;
        position: absolute;
        top: -40px;
        right: -105px;
        width: 100vw;
        height: 132px;
        clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0 100%);
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
        z-index: 1; }
        @media (max-width: 1023px) {
          #main-header .flex-wrapper .logo::before {
            top: -65px;
            right: -45px; } }
      #main-header .flex-wrapper .logo a {
        display: inline-block;
        position: relative;
        z-index: 2; }
        @media (max-width: 1023px) {
          #main-header .flex-wrapper .logo a img {
            width: 150px; } }
    #main-header .flex-wrapper .right {
      display: flex;
      align-items: center; }
    #main-header .flex-wrapper .menu-toggler {
      display: none;
      align-items: center;
      justify-content: center;
      pointer-events: initial; }
      @media (max-width: 1023px) {
        #main-header .flex-wrapper .menu-toggler {
          display: flex; } }
      #main-header .flex-wrapper .menu-toggler button {
        position: relative;
        width: 48px;
        height: 48px;
        transition-property: width, height, background-color;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-header .flex-wrapper .menu-toggler button span {
          background-color: white;
          position: absolute;
          right: 0;
          height: 2px;
          transform: translateY(-50%);
          cursor: pointer;
          transition-property: width, background-color, right;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-header .flex-wrapper .menu-toggler button span:nth-child(1) {
            top: calc(50% - 6px);
            width: 48px; }
          #main-header .flex-wrapper .menu-toggler button span:nth-child(2) {
            top: calc(50% + 6px);
            width: 24px; }
    #main-header .flex-wrapper .navigation {
      margin-right: 32px; }
      @media (max-width: 1023px) {
        #main-header .flex-wrapper .navigation {
          display: none; } }
      #main-header .flex-wrapper .navigation ul {
        display: flex;
        align-items: center; }
        #main-header .flex-wrapper .navigation ul li:not(:last-child) {
          margin-right: 32px; }
        #main-header .flex-wrapper .navigation ul li a {
          color: #f2f2f2;
          font-size: 14px;
          font-weight: 700;
          display: inline-block;
          transition-property: color;
          transition-duration: 0.7s;
          transition-duration: cubic-bezier(0.5, 0, 0, 1); }
          #main-header .flex-wrapper .navigation ul li a:hover {
            color: #e95116; }
    #main-header .flex-wrapper .login {
      margin-right: 32px; }
      @media (max-width: 1023px) {
        #main-header .flex-wrapper .login {
          display: none; } }
    @media (max-width: 1023px) {
      #main-header .flex-wrapper .languages {
        display: none; } }
    #main-header .flex-wrapper .languages > div {
      padding: 0;
      border: none; }
      #main-header .flex-wrapper .languages > div ul {
        display: flex;
        align-items: center; }
        #main-header .flex-wrapper .languages > div ul li {
          position: relative;
          margin: 0;
          padding: 0;
          list-style: none; }
          #main-header .flex-wrapper .languages > div ul li:not(:last-child) {
            margin-right: 20px; }
            #main-header .flex-wrapper .languages > div ul li:not(:last-child)::after {
              content: '|';
              position: absolute;
              color: #21bbef;
              font-size: 20px;
              font-weight: normal;
              position: absolute;
              right: -15px;
              bottom: 1px; }
          #main-header .flex-wrapper .languages > div ul li a {
            color: #f2f2f2;
            font-size: 14px;
            font-weight: normal;
            padding: 0; }
          #main-header .flex-wrapper .languages > div ul li.wpml-ls-current-language a {
            font-weight: bold;
            border-bottom: 1px solid white; }
  #main-header.sticky {
    background-color: white; }
    #main-header.sticky .flex-wrapper {
      padding: 25px 0; }
      #main-header.sticky .flex-wrapper .logo::before {
        opacity: 0; }
      #main-header.sticky .flex-wrapper .menu-toggler button span {
        background-color: #0071bb; }
      #main-header.sticky .flex-wrapper .navigation ul li a {
        color: #0071bb; }
      #main-header.sticky .flex-wrapper .navigation ul li:hover a {
        color: #e95116; }
      #main-header.sticky .flex-wrapper .navigation ul li.active a {
        color: #e95116; }
      #main-header.sticky .flex-wrapper .languages div ul li a {
        color: #0071bb; }
      #main-header.sticky .flex-wrapper .languages div ul li.wpml-ls-current-language a {
        border-bottom: 1px solid #0071bb; }
  #main-header[theme='single'] .logo::before {
    display: none; }
  #main-header[theme='single'] .menu-toggler button span {
    background-color: #0071bb; }
  #main-header[theme='single'] .navigation ul li a {
    color: #0071bb; }
  #main-header[theme='single'] .navigation ul li:hover a {
    color: #e95116; }
  #main-header[theme='single'] .navigation ul li.active a {
    color: #e95116; }
  #main-header[theme='single'] .languages div ul li a {
    color: #0071bb; }
  #main-header[theme='single'] .languages div ul li.wpml-ls-current-language a {
    border-bottom: 1px solid #0071bb; }

#main-footer {
  background-color: white;
  padding: 60px 0 35px; }
  @media (max-width: 1023px) {
    #main-footer {
      padding: 75px 0 35px; } }
  #main-footer .flex-wrapper {
    padding-right: 55px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper {
        flex-direction: column;
        align-items: center;
        padding: 0; } }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper .left {
        display: flex;
        flex-direction: column;
        align-items: center; } }
    #main-footer .flex-wrapper .left-navigation {
      margin-top: 70px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .left-navigation {
          margin-top: 40px; } }
      #main-footer .flex-wrapper .left-navigation ul {
        display: flex;
        align-items: flex-start; }
        #main-footer .flex-wrapper .left-navigation ul li:not(:last-child) {
          margin-right: 40px; }
          @media (max-width: 1023px) {
            #main-footer .flex-wrapper .left-navigation ul li:not(:last-child) {
              margin-right: 20px; } }
        #main-footer .flex-wrapper .left-navigation ul li a {
          color: #0071bb;
          font-size: 14px;
          font-weight: 500;
          transition-property: color;
          transition-duration: 0.5s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-footer .flex-wrapper .left-navigation ul li a:hover {
            color: #e95116; }
    #main-footer .flex-wrapper .left-copyright {
      margin-top: 45px; }
      #main-footer .flex-wrapper .left-copyright p {
        color: #333333;
        font-size: 12px;
        font-weight: 500; }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px; } }
    #main-footer .flex-wrapper .right-social ul {
      display: flex;
      align-items: ce; }
      #main-footer .flex-wrapper .right-social ul li:not(:last-child) {
        margin-right: 32px; }
        @media (max-width: 1023px) {
          #main-footer .flex-wrapper .right-social ul li:not(:last-child) {
            margin-right: 20px; } }
      #main-footer .flex-wrapper .right-social ul li a {
        display: inline-flex;
        align-items: center; }
        #main-footer .flex-wrapper .right-social ul li a svg {
          width: 22px; }
          #main-footer .flex-wrapper .right-social ul li a svg path {
            transition-property: fill;
            transition-duration: 0.5s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-footer .flex-wrapper .right-social ul li a:hover span {
          color: #e95116; }
        #main-footer .flex-wrapper .right-social ul li a:hover svg path {
          fill: #e95116; }
    #main-footer .flex-wrapper .right-signature {
      margin-top: 95px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .right-signature {
          margin-top: 40px; } }

#main-menu {
  background-color: white;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  overflow: hidden;
  z-index: 55; }
  #main-menu.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial; }
  #main-menu .close {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 5; }
    #main-menu .close svg rect {
      fill: #0071bb; }
  #main-menu .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%; }
    #main-menu .wrapper .navigation {
      margin: 0 0 32px; }
      #main-menu .wrapper .navigation ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        #main-menu .wrapper .navigation ul li:not(:last-child) {
          margin: 0 0 20px; }
        #main-menu .wrapper .navigation ul li a {
          color: #0071bb;
          font-size: 14px;
          font-weight: 700;
          display: inline-block;
          transition-property: color;
          transition-duration: 0.7s;
          transition-duration: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .wrapper .navigation ul li a:hover {
            color: #e95116; }
    #main-menu .wrapper .login {
      margin: 0 0 32px; }
    #main-menu .wrapper .languages > div {
      padding: 0;
      border: none; }
      #main-menu .wrapper .languages > div ul {
        display: flex;
        align-items: center; }
        #main-menu .wrapper .languages > div ul li {
          position: relative;
          margin: 0;
          padding: 0;
          list-style: none; }
          #main-menu .wrapper .languages > div ul li:not(:last-child) {
            margin-right: 20px; }
            #main-menu .wrapper .languages > div ul li:not(:last-child)::after {
              content: '|';
              position: absolute;
              color: #21bbef;
              font-size: 20px;
              font-weight: normal;
              position: absolute;
              right: -15px;
              bottom: 1px; }
          #main-menu .wrapper .languages > div ul li a {
            color: #0071bb;
            font-size: 14px;
            font-weight: normal;
            padding: 0; }
          #main-menu .wrapper .languages > div ul li.wpml-ls-current-language a {
            font-weight: bold;
            border-bottom: 1px solid #0071bb; }
