@font-face {
  font-family: 'Campuni';
  src: url('../../assets/fonts/Campuni-Black.woff2') format('woff2'),
    url('../../assets/fonts/Campuni-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campuni';
  src: url('../../assets/fonts/Campuni-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Campuni-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campuni';
  src: url('../../assets/fonts/Campuni-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Campuni-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
