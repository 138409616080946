#main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition-property: background-color;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 50;

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    transition-property: padding;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    @media (max-width: 1023px) {
      padding: 25px 0;
    }

    .logo {
      position: relative;

      &::before {
        content: '';
        background-color: white;
        position: absolute;
        top: -40px;
        right: -105px;
        width: 100vw;
        height: 132px;
        clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0 100%);
        transition-property: opacity;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
        z-index: 1;

        @media (max-width: 1023px) {
          top: -65px;
          right: -45px;
        }
      }

      a {
        display: inline-block;
        position: relative;
        z-index: 2;

        img {
          @media (max-width: 1023px) {
            width: 150px;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
    }

    .menu-toggler {
      display: none;
      align-items: center;
      justify-content: center;
      pointer-events: initial;

      @media (max-width: 1023px) {
        display: flex;
      }

      button {
        position: relative;
        width: 48px;
        height: 48px;
        transition-property: width, height, background-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        span {
          background-color: white;
          position: absolute;
          right: 0;
          height: 2px;
          transform: translateY(-50%);
          cursor: pointer;
          transition-property: width, background-color, right;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:nth-child(1) {
            top: calc(50% - 6px);
            width: 48px;
          }

          &:nth-child(2) {
            top: calc(50% + 6px);
            width: 24px;
          }
        }
      }
    }

    .navigation {
      margin-right: 32px;

      @media (max-width: 1023px) {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          &:not(:last-child) {
            margin-right: 32px;
          }

          a {
            color: #f2f2f2;
            font-size: 14px;
            font-weight: 700;
            display: inline-block;
            transition-property: color;
            transition-duration: time(default);
            transition-duration: ease(inout);

            &:hover {
              color: #e95116;
            }
          }
        }
      }
    }

    .login {
      margin-right: 32px;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    .languages {
      @media (max-width: 1023px) {
        display: none;
      }

      > div {
        padding: 0;
        border: none;

        ul {
          display: flex;
          align-items: center;

          li {
            position: relative;
            margin: 0;
            padding: 0;
            list-style: none;

            &:not(:last-child) {
              margin-right: 20px;

              &::after {
                content: '|';
                position: absolute;
                color: #21bbef;
                font-size: 20px;
                font-weight: normal;
                position: absolute;
                right: -15px;
                bottom: 1px;
              }
            }

            a {
              color: #f2f2f2;
              font-size: 14px;
              font-weight: normal;
              padding: 0;
            }

            &.wpml-ls-current-language a {
              font-weight: bold;
              border-bottom: 1px solid white;
            }
          }
        }
      }
    }
  }

  &.sticky {
    background-color: white;

    .flex-wrapper {
      padding: 25px 0;

      .logo::before {
        opacity: 0;
      }

      .menu-toggler button span {
        background-color: #0071bb;
      }

      .navigation ul li {
        a {
          color: #0071bb;
        }

        &:hover a {
          color: #e95116;
        }

        &.active a {
          color: #e95116;
        }
      }

      .languages div ul li {
        a {
          color: #0071bb;
        }

        &.wpml-ls-current-language a {
          border-bottom: 1px solid #0071bb;
        }
      }
    }
  }

  &[theme='single'] {
    .logo::before {
      display: none;
    }

    .menu-toggler button span {
      background-color: #0071bb;
    }

    .navigation ul li {
      a {
        color: #0071bb;
      }

      &:hover a {
        color: #e95116;
      }

      &.active a {
        color: #e95116;
      }
    }

    .languages div ul li {
      a {
        color: #0071bb;
      }

      &.wpml-ls-current-language a {
        border-bottom: 1px solid #0071bb;
      }
    }
  }
}
